import cx from "classnames";
import React from "react";

export type InputDecoratorProps = {
  hint?: string | React.ReactNode;
  isFocused?: boolean;
  isRaised?: boolean;
};

export const InputDecoratorTop = ({
  hint,
  isFocused,
  isRaised = false,
}: InputDecoratorProps) => {
  const isFocusedStyleForced = isFocused !== undefined;

  return (
    <>
      {!!hint && (
        <div
          className={cx(
            "hidden peer-focus:block relative -z-10 pb-2 w-0 min-w-full",
            // Forcing the focused style if not achievable through tailwind peer or group
            { "!block": isFocusedStyleForced && isFocused },
            { "!z-50 !top-0": isRaised },
          )}
        >
          {hint}
        </div>
      )}
    </>
  );
};
