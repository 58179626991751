import cx from "classnames";
import React from "react";
import { useFormContext } from "react-hook-form";

import { PasswordFormData } from "app/commander/verification/Password/UpdatePasswordModal";
import { useI18n } from "utils/with-i18n.utils";

import { PasswordRequirementLine, State } from "./PasswordRequirementLine";

type PasswordRequirementsProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLUListElement>,
  HTMLUListElement
>;

export const PasswordRequirements = ({
  className,
  ...props
}: PasswordRequirementsProps) => {
  const i18n = useI18n();
  const { formState } = useFormContext<PasswordFormData>();
  const isFormDirty = formState.isDirty;

  const getRequirementState = (errorName: string) => {
    if (!isFormDirty) {
      return State.Untouched;
    }

    const isFieldInErrorState =
      formState.errors.newPassword?.types?.[errorName];

    return isFieldInErrorState ? State.Invalid : State.Valid;
  };

  return (
    <ul className={cx("flex flex-col gap-2", className)} {...props}>
      <PasswordRequirementLine
        state={getRequirementState("minLength")}
        requirementLabel={i18n.t(
          "auth.passwordForm.field.passwordRequirement.minChar",
        )}
      />
      <PasswordRequirementLine
        state={getRequirementState("lower")}
        requirementLabel={i18n.t(
          "auth.passwordForm.field.passwordRequirement.lowercase",
        )}
      />
      <PasswordRequirementLine
        state={getRequirementState("upper")}
        requirementLabel={i18n.t(
          "auth.passwordForm.field.passwordRequirement.uppercase",
        )}
      />
      <PasswordRequirementLine
        state={getRequirementState("number")}
        requirementLabel={i18n.t(
          "auth.passwordForm.field.passwordRequirement.numeral",
        )}
      />
    </ul>
  );
};
