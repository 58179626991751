import cx from "classnames";
import React from "react";

import GreenCheck from "./green-check.svg";

export type InputDecoratorProps = {
  hint?: string | React.ReactNode;
  errorLabel?: string;
  isValid?: boolean;
  isFocused?: boolean;
  isRaised?: boolean;
  backgroundVariant?: "info" | "warn";
};

export const InputDecoratorBottom = ({
  hint,
  errorLabel,
  isValid = false,
  isFocused,
  isRaised = false,
  backgroundVariant = "info",
}: InputDecoratorProps) => {
  const isErrorLabelShown = !!errorLabel;
  const isFocusedStyleForced = isFocused !== undefined;

  return (
    <>
      {!!hint && (
        <div
          className={cx(
            "hidden peer-focus:block relative -top-2 -z-10 px-4 pt-3 pb-2 -mb-2 text-sm rounded-b-lg w-0 min-w-full",
            // Forcing the focused style if not achievable through tailwind peer or group
            { "!block": isFocusedStyleForced && isFocused },
            { "!z-50 !top-0": isRaised },
            backgroundVariant === "info" ? "bg-aths-special" : "bg-[#FFF6C1]",
          )}
        >
          {hint}
        </div>
      )}
      {isErrorLabelShown && (
        <div className="mt-1 -mb-2 text-french-rose">{errorLabel}</div>
      )}
      {isValid && <GreenCheck className="absolute top-5 right-4" />}
    </>
  );
};
