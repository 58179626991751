import { RegisterOptions } from "react-hook-form";

const PASSWORD_VALIDATION_REGEXES = {
  hasLowerCase: /(?=.*[a-z])/,
  hasUpperCase: /(?=.*[A-Z])/,
  hasNumber: /(?=.*\d)/,
};

export const getPasswordRules = (
  requiredPasswordTranslation: string,
): RegisterOptions => ({
  required: { value: true, message: requiredPasswordTranslation },
  minLength: 8,
  validate: {
    lower: (value: string) =>
      Boolean(value.match(PASSWORD_VALIDATION_REGEXES.hasLowerCase)),
    upper: (value: string) =>
      Boolean(value.match(PASSWORD_VALIDATION_REGEXES.hasUpperCase)),
    number: (value: string) =>
      Boolean(value.match(PASSWORD_VALIDATION_REGEXES.hasNumber)),
  },
});
