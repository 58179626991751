import { useI18n } from "utils/with-i18n.utils";

export const ResetConfirm = () => {
  const i18n = useI18n();

  return (
    <>
      <h3 className="text-4xl font-medium">{i18n.t("auth.askReset.title")}</h3>
      <p className="my-4">{i18n.t("auth.askReset.sent", {}, { html: true })}</p>
    </>
  );
};
