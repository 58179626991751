import cx from "classnames";
import React from "react";
import { match } from "ts-pattern";

import InvalidPill from "./invalid-pill.svg";
import UntouchedPill from "./untouched-pill.svg";
import ValidPill from "./valid-pill.svg";

export enum State {
  Invalid,
  Untouched,
  Valid,
}

const getIcon = (state: State) =>
  match(state)
    .with(State.Invalid, () => <InvalidPill />)
    .with(State.Untouched, () => <UntouchedPill />)
    .with(State.Valid, () => <ValidPill />)
    .otherwise(() => null);

type PasswordRequirementProps = {
  state: State;
  requirementLabel: string;
};

export const PasswordRequirementLine = ({
  requirementLabel,
  state,
}: PasswordRequirementProps) => (
  <li className="flex gap-2 items-center">
    {getIcon(state)}
    <p
      className={cx({
        "text-pink": state === State.Invalid,
        "text-gray": state === State.Untouched,
      })}
    >
      {requirementLabel}
    </p>
  </li>
);
