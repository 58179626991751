import * as Types from '../../graphql.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type AskResetPasswordMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type AskResetPasswordMutation = { __typename?: 'mutation_root', result: { __typename?: 'SuccessObjectDto', success: boolean, message?: Types.Maybe<string> } };

export type FinalizePasswordResetMutationVariables = Types.Exact<{
  password: Types.Scalars['String'];
  token: Types.Scalars['String'];
}>;


export type FinalizePasswordResetMutation = { __typename?: 'mutation_root', result: { __typename?: 'SuccessObjectDto', success: boolean, message?: Types.Maybe<string> } };


export const AskResetPasswordDocument = gql`
    mutation AskResetPassword($email: String!) {
  result: nest_askResetCustomerPassword(email: $email) {
    success
    message
  }
}
    `;
export function useAskResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<AskResetPasswordMutation, AskResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AskResetPasswordMutation, AskResetPasswordMutationVariables>(AskResetPasswordDocument, options);
      }
export type AskResetPasswordMutationHookResult = ReturnType<typeof useAskResetPasswordMutation>;
export type AskResetPasswordMutationResult = Apollo.MutationResult<AskResetPasswordMutation>;
export type AskResetPasswordMutationOptions = Apollo.BaseMutationOptions<AskResetPasswordMutation, AskResetPasswordMutationVariables>;
export const FinalizePasswordResetDocument = gql`
    mutation FinalizePasswordReset($password: String!, $token: String!) {
  result: nest_resetCustomerPassword(password: $password, token: $token) {
    success
    message
  }
}
    `;
export function useFinalizePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<FinalizePasswordResetMutation, FinalizePasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizePasswordResetMutation, FinalizePasswordResetMutationVariables>(FinalizePasswordResetDocument, options);
      }
export type FinalizePasswordResetMutationHookResult = ReturnType<typeof useFinalizePasswordResetMutation>;
export type FinalizePasswordResetMutationResult = Apollo.MutationResult<FinalizePasswordResetMutation>;
export type FinalizePasswordResetMutationOptions = Apollo.BaseMutationOptions<FinalizePasswordResetMutation, FinalizePasswordResetMutationVariables>;