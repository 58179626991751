import cx from "classnames";
import React, { useState } from "react";
import { FieldError, Path, useFormContext } from "react-hook-form";
import { RegisterOptions } from "react-hook-form/dist/types/validator";

import {
  InputDecoratorBottom,
  InputDecoratorProps,
} from "shared/forms/InputDecorator/InputDecoratorBottom";
import { InputPlaceholder } from "shared/forms/InputPlaceholder";

import { InputDecoratorTop } from "./InputDecorator/InputDecoratorTop";

type InputComboExtraProps<T extends Record<string, any>> = {
  fieldName: Path<T>;
  fieldOptions?: RegisterOptions<T>;
  hintTop?: string | React.ReactNode;
  hintBottom?: string | React.ReactNode;
  isErrorShown?: boolean;
  hideRequiredMark?: boolean;
  suffix?: string | React.ReactNode;
};

type InputComboProps<T extends Record<string, any>> = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  InputComboExtraProps<T> &
  Pick<InputDecoratorProps, "isRaised" | "backgroundVariant">;

export const InputCombo = <T extends Record<string, any>>({
  fieldName,
  fieldOptions,
  className,
  placeholder,
  hintTop,
  hintBottom,
  isErrorShown = true,
  hideRequiredMark = false,
  isRaised,
  backgroundVariant,
  suffix,
  ...props
}: InputComboProps<T>) => {
  const [isFocused, setIsFocused] = useState(false);
  const { formState, register } = useFormContext<T>();

  const error = formState.errors[fieldName] as FieldError | undefined;
  const errorLabel = isErrorShown ? error?.message : undefined;
  const isTouched = !!formState.touchedFields[fieldName];
  const isValid = isTouched && !error;
  const isRequired = !!fieldOptions?.required;
  const isDisabled = formState.isSubmitting;
  const isHintTopShown = !!hintTop;

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const shouldShowPlaceHolder = () => {
    // in case of focusing with a hint top message
    if (isHintTopShown && isFocused) {
      return false;
    }

    return true;
  };

  return (
    <div className={cx("relative", className)}>
      {isHintTopShown && (
        <InputDecoratorTop
          hint={hintTop}
          isRaised={isRaised}
          isFocused={isFocused}
        />
      )}

      <div className={"relative"}>
        <input
          id={fieldName}
          value={props.value}
          className={cx(
            "peer pr-10 pl-3.5 font-medium placeholder:text-transparent w-full h-12 rounded-lg border [&::-webkit-inner-spin-button]:appearance-none",
            !!errorLabel ? "border-french-rose" : "border-black/35",
          )}
          placeholder={placeholder}
          disabled={isDisabled}
          autoCapitalize={
            props.autoCapitalize ? props.autoCapitalize : "sentences"
          }
          {...props}
          {...register(fieldName, fieldOptions)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyPress={props.onKeyPress}
          onPaste={props.onPaste}
          onChange={props.onChange}
        />
        {suffix && (
          <div
            className="flex absolute inset-y-0 items-center pointer-events-none"
            style={{ insetInlineEnd: "10px" }}
          >
            {suffix}
          </div>
        )}
      </div>

      {shouldShowPlaceHolder() && placeholder && (
        <InputPlaceholder
          id={fieldName}
          placeholder={placeholder}
          isRequired={!hideRequiredMark && isRequired}
        />
      )}

      <InputDecoratorBottom
        hint={hintBottom}
        errorLabel={errorLabel}
        isValid={isValid}
        isRaised={isRaised}
        backgroundVariant={backgroundVariant}
        isFocused={isFocused}
      />
    </div>
  );
};
