import cx from "classnames";
import React from "react";

type InputLabelProps = {
  id?: string;
  placeholder: string;
  isRequired?: boolean;
  className?: string;
};

export const InputPlaceholder = ({
  id,
  placeholder,
  isRequired = false,
  className,
}: InputLabelProps) => (
  <label
    htmlFor={id}
    className={cx(
      [
        "absolute text-xs -top-2 left-3 px-1 text-black/35 bg-white transition-all pointer-events-none",
        "peer-placeholder-shown:top-3 peer-focus:-top-2 peer-focus:text-xs peer-focus:bg-white",
        "peer-focus:text-black peer-placeholder-shown:bg-transparent peer-placeholder-shown:text-base",
      ],
      { "after:content-['*'] after:text-french-rose": isRequired },
      className,
    )}
  >
    {placeholder}
  </label>
);
