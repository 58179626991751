import { useRouter } from "next/router";
import type { UseFormReturn } from "react-hook-form";

import { useI18n } from "utils/with-i18n.utils";

import { useFinalizePasswordResetMutation } from "./reset-password.mutation.generated";

export interface PasswordFormData {
  newPassword: string;
  passwordConfirm: string;
}

export const useFinalizePasswordReset = (
  form: UseFormReturn<PasswordFormData>,
) => {
  const i18n = useI18n();

  const { replace } = useRouter();

  const navigateToLogin = () => replace("/login");

  const [finalizeReset, { loading }] = useFinalizePasswordResetMutation({
    onCompleted: navigateToLogin,
    onError: () => {
      form.setError("newPassword", {
        type: "error",
        message: i18n.t("auth.finalizeReset.error"),
      });
      form.setError("passwordConfirm", {
        type: "error",
        message: i18n.t("auth.finalizeReset.error"),
      });
    },
  });

  return { finalizeLoading: loading, finalizeReset };
};
