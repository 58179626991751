export function parseGraphQLError(error: any): string {
  if (error?.graphQLErrors?.[0]?.message) {
    return error.graphQLErrors[0].message;
  }

  if (typeof error === "string") {
    return error;
  }

  return "UNKNOWN_ERROR";
}
