import * as Types from '../../graphql.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LoginMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'mutation_root', result: { __typename?: 'AuthError', success: boolean, rateLimit?: Types.Maybe<{ __typename?: 'RateLimitDto', consumed: number, remaining: number, secondsBeforeReset: number }> } | { __typename?: 'AuthSuccess', success: boolean, token: string, isBusiness: boolean } };


export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  result: nest_loginCustomer(email: $email, password: $password) {
    ... on AuthSuccess {
      success
      token
      isBusiness
    }
    ... on AuthError {
      success
      rateLimit {
        consumed
        remaining
        secondsBeforeReset
      }
    }
  }
}
    `;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;