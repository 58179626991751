import { useState } from "react";
import type { ErrorOption } from "react-hook-form";

import { parseGraphQLError } from "utils/parse-graphql-error.utils";
import { LoginFormData } from "utils/useLogin";
import { useI18n } from "utils/with-i18n.utils";

import { useAskResetPasswordMutation } from "./reset-password.mutation.generated";

const EMAIL_NOT_FOUND = "EMAIL_NOT_FOUND";

export const useAskResetPassword = (
  setError?: (name: keyof LoginFormData, error: ErrorOption) => void,
) => {
  const [hasAskedResetEmail, setHasAskedResetEmail] = useState(false);

  const i18n = useI18n();

  const [resetPasswordMutation] = useAskResetPasswordMutation({
    onCompleted: () => setHasAskedResetEmail(true),
    onError: (error) => {
      if (!setError) return;

      const errorMessage = parseGraphQLError(error);

      setError("email", {
        type: "error",
        message: i18n.t(
          errorMessage === EMAIL_NOT_FOUND
            ? "auth.askReset.error.notFound"
            : "auth.askReset.error",
        ),
      });
    },
  });

  return {
    resetPasswordMutation,
    hasAskedResetEmail,
    setHasAskedResetEmail,
  };
};
