import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { useSettings } from "shared/SettingsProvider/useSettings";
import { LoginFormData, useLogin } from "utils/useLogin";
import { useAskResetPassword } from "utils/usePasswordReset/useAskResetPassword";
import { useI18n } from "utils/with-i18n.utils";

import { FormFields } from "./FormFields";
import { ResetConfirm } from "./ResetConfirm";

export const LoginForm = () => {
  const i18n = useI18n();

  const { reload } = useRouter();

  const form = useForm<LoginFormData>({ mode: "onBlur" });

  const { isBusiness } = useSettings();

  const [hasLostPassword, setHasLostPassword] = useState(false);

  const { login, isLoading } = useLogin(form.setError);
  const { resetPasswordMutation, hasAskedResetEmail } = useAskResetPassword(
    form.setError,
  );

  const handleSubmit = form.handleSubmit(async (variables: LoginFormData) =>
    hasLostPassword
      ? resetPasswordMutation({
          variables: {
            email: variables.email,
          },
        })
      : login(variables),
  );

  const handleHasLostPassword = () => setHasLostPassword(true);

  const handleCancelLostPassword = () =>
    hasAskedResetEmail ? reload() : setHasLostPassword(false);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="max-w-sm">
        {hasAskedResetEmail ? (
          <ResetConfirm />
        ) : (
          <FormFields
            onHasLostPassword={handleHasLostPassword}
            hasLostPassword={hasLostPassword}
            isLoading={isLoading || form.formState.isSubmitting}
          />
        )}
        {hasLostPassword ? (
          <button onClick={handleCancelLostPassword} type="button">
            {i18n.t("auth.backToLogin.cta")}
            <span className="font-bold underline">
              {i18n.t("auth.cta.login")}
            </span>
          </button>
        ) : (
          <Link
            href={
              isBusiness ? "/business/contacter-un-conseiller" : "/smartphone"
            }
          >
            <a>
              {i18n.t(
                isBusiness
                  ? "auth.business.notCustomerYet"
                  : "auth.notCustomerYet",
              )}
              <span className="font-bold underline">
                {i18n.t(isBusiness ? "auth.business.joinUs" : "auth.joinUs")}
              </span>
            </a>
          </Link>
        )}
      </form>
    </FormProvider>
  );
};
