import cx from "classnames";
import { useRouter } from "next/router";
import React from "react";
import { FormProvider, RegisterOptions, useForm } from "react-hook-form";
import { match, P } from "ts-pattern";

import { useSettings } from "shared/SettingsProvider/useSettings";
import { InputCombo } from "shared/forms/InputCombo";
import { getPasswordRules } from "shared/forms/Password";
import { PasswordRequirements } from "shared/forms/Password/PasswordRequirements";
import { Button } from "ui/Button";
import {
  PasswordFormData,
  useFinalizePasswordReset,
} from "utils/usePasswordReset/useFinalizePasswordReset";
import { useI18n } from "utils/with-i18n.utils";

type ResetPasswordFormProps = { token: string };

export const ResetPasswordForm = ({ token }: ResetPasswordFormProps) => {
  const i18n = useI18n();
  const {
    query: { createPassword },
  } = useRouter();

  const { isBusiness } = useSettings();
  const titleTranslationKey = match([isBusiness, !!createPassword])
    .with([true, P._], () => "auth.business.title.finalizeReset")
    .with([false, true], () => "auth.title.createPassword")
    .otherwise(() => "auth.title.finalizeReset");

  const buttonTranslationKey = createPassword
    ? "auth.cta.createPassword"
    : "auth.cta.finalizeReset";

  const form = useForm<PasswordFormData>({
    shouldUnregister: true,
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
  });

  const newPasswordValue = form.watch("newPassword");

  const { finalizeLoading, finalizeReset } = useFinalizePasswordReset(form);

  const passwordRules: RegisterOptions = getPasswordRules(
    i18n.t("auth.password.required"),
  );

  const passwordConfirmRules: RegisterOptions = {
    required: {
      value: true,
      message: i18n.t("errors.required.passwordConfirm"),
    },
    validate: (value: string) =>
      newPasswordValue === value ||
      i18n.t("auth.passwordForm.field.password.passwordConfirmation.validate"),
  };

  const handleSubmit = form.handleSubmit(
    async ({ newPassword }: PasswordFormData) =>
      await finalizeReset({
        variables: { token, password: newPassword },
      }),
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="max-w-sm">
        <h3 className="text-4xl font-medium">{i18n.t(titleTranslationKey)}</h3>
        <InputCombo
          autoComplete="new-password"
          fieldName="newPassword"
          type="password"
          fieldOptions={passwordRules}
          placeholder={i18n.t("auth.newPassword.placeholder")}
          className={cx("mt-4", isBusiness && "text-black")}
          {...form.register("newPassword")}
        />
        <InputCombo
          autoComplete="new-password"
          fieldName="passwordConfirm"
          type="password"
          fieldOptions={passwordConfirmRules}
          placeholder={i18n.t("fields.passwordConfirm")}
          className={cx("mt-4", isBusiness && "text-black")}
          {...form.register("passwordConfirm")}
        />
        <PasswordRequirements className="lg:m-2" />
        <Button
          className={cx(
            "mt-4 w-full text-black",
            isBusiness && "shadow-[4px_4px_white]",
          )}
          type="submit"
          isLoading={form.formState.isSubmitting || finalizeLoading}
          disabled={!form.formState.isValid}
        >
          {i18n.t(buttonTranslationKey)}
        </Button>
      </form>
    </FormProvider>
  );
};
