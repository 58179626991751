import { useRouter } from "next/router";
import { useState } from "react";
import { useCallback } from "react";
import type { ErrorOption } from "react-hook-form";
import { match } from "ts-pattern";

import { BUSINESS_STORE_PATH } from "app/business/constants";
import { config } from "utils/config.utils";
import { setCookie } from "utils/cookies.utils";
import { useI18n } from "utils/with-i18n.utils";

import {
  LoginMutationVariables,
  useLoginMutation,
} from "./login.mutation.generated";

const DELAY_LOGIN_DURATION = 2000;

export type LoginFormData = {
  email: string;
  password: string;
};

export const useLogin = (
  setError: (name: keyof LoginFormData, error: ErrorOption) => void,
) => {
  const i18n = useI18n();
  const router = useRouter();
  const [isLoading, setLoading] = useState(false);

  const [loginMutation] = useLoginMutation({
    onCompleted: ({ result }) => {
      match(result)
        .with({ __typename: "AuthSuccess" }, async ({ isBusiness, token }) => {
          const cookieKey = isBusiness
            ? config.b2b_session_cookie
            : config.session_cookie;
          setCookie(cookieKey, token);

          if (typeof router.query.redirection === "string") {
            return window.location.replace(router.query.redirect as string);
          } else if (isBusiness) {
            await router.replace(BUSINESS_STORE_PATH);
          } else {
            const params = new URLSearchParams(document.location.search);
            const isOpenReferralModal = params.get("isOpenReferralModal");

            if (isOpenReferralModal) {
              await router.replace("/espace-membre?isOpenReferralModal=true");
            } else {
              await router.replace("/espace-membre");
            }
          }
        })
        .with({ __typename: "AuthError" }, ({ rateLimit }) => {
          setError("password", {
            type: "error",
            message: i18n.t(
              rateLimit ? "auth.login.error.banned" : "auth.login.error",
            ),
          });
        })
        .otherwise(() => setLoading(false));

      setLoading(false);
    },
  });

  const delayedLoginMutation = useCallback(
    (...args: Parameters<typeof loginMutation>) =>
      new Promise((resolve, reject) =>
        setTimeout(
          () =>
            loginMutation(...args)
              .then(resolve)
              .catch(reject),
          DELAY_LOGIN_DURATION,
        ),
      ),
    [loginMutation],
  );

  const login = useCallback(
    async (variables: LoginMutationVariables) => {
      setLoading(true);

      await delayedLoginMutation({ variables });
    },
    [delayedLoginMutation],
  );

  return { login, isLoading };
};
