import cx from "classnames";
import { RegisterOptions, useFormContext } from "react-hook-form";
import { match } from "ts-pattern";

import { useSettings } from "shared/SettingsProvider/useSettings";
import { InputCombo } from "shared/forms/InputCombo";
import { Button, Shadow } from "ui/Button";
import { LoginFormData } from "utils/useLogin";
import { isEmail } from "utils/validators.utils";
import { useI18n } from "utils/with-i18n.utils";

type FormFieldsProps = {
  hasLostPassword: boolean;
  isLoading: boolean;
  onHasLostPassword: () => void;
};

const formTitleTranslationKey = (
  hasLostPassword: FormFieldsProps["hasLostPassword"],
  isBusiness: boolean,
) =>
  match({ hasLostPassword, isBusiness })
    .with({ hasLostPassword: true }, () => "auth.title.askReset")
    .with({ isBusiness: true }, () => "auth.business.title.login")
    .otherwise(() => "auth.title.login");

export const FormFields = ({
  onHasLostPassword,
  hasLostPassword,
  isLoading,
}: FormFieldsProps) => {
  const i18n = useI18n();

  const form = useFormContext<LoginFormData>();

  const { isBusiness } = useSettings();

  const emailRules: RegisterOptions = {
    required: i18n.t("auth.email.required"),
    validate: {
      email: (value: string) => isEmail(value) || i18n.t("auth.email.error"),
    },
  };

  const passwordRules: RegisterOptions = {
    required: i18n.t("auth.password.required"),
  };

  return (
    <>
      <h3 className="pr-4 text-4xl font-medium lg:pr-0">
        {i18n.t(
          formTitleTranslationKey(hasLostPassword, isBusiness),
          {},
          { html: true },
        )}
      </h3>
      {hasLostPassword && (
        <p className="mt-3">
          {i18n.t(
            isBusiness ? "auth.business.desc.askReset" : "auth.desc.askReset",
          )}
        </p>
      )}
      <InputCombo
        autoComplete="email"
        autoCapitalize="none"
        fieldName="email"
        fieldOptions={emailRules}
        hideRequiredMark={true}
        placeholder={i18n.t(
          isBusiness
            ? "auth.business.email.placeholder"
            : "auth.email.placeholder",
        )}
        className={cx("mt-4", isBusiness && "text-black")}
        {...form.register("email")}
      />

      {!hasLostPassword && (
        <>
          <InputCombo
            autoComplete="current-password"
            fieldName="password"
            type="password"
            fieldOptions={passwordRules}
            hideRequiredMark={true}
            placeholder={i18n.t(
              isBusiness
                ? "auth.business.password.placeholder"
                : "auth.password.placeholder",
            )}
            className={cx("mt-4", isBusiness && "text-black")}
            {...form.register("password")}
          />
          <button
            className="mt-4 underline opacity-60"
            type="button"
            onClick={onHasLostPassword}
          >
            {i18n.t("auth.forgotPasword")}
          </button>
        </>
      )}

      <Button
        type="submit"
        className="my-6 w-full text-black"
        shadow={isBusiness ? Shadow.White : Shadow.Black}
        isLoading={form.formState.isSubmitting || isLoading}
      >
        {i18n.t(hasLostPassword ? "auth.cta.askReset" : "auth.cta.login")}
      </Button>
    </>
  );
};
